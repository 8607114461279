<!-- SeatCategoriesPicker.vue -->
<template>
  <v-card class="overflow-y-hidden mt-3" elevation="0">
    <v-card-text class="categories-content">
      <v-list dense class="pa-0">
        <v-list-group v-for="category in seatCategories" :key="category.id" v-model="category.active" no-action
          :style="{ borderLeft: `10px solid ${category.color} !important`, borderBottom: '1px solid lightgrey' }">
          <template v-slot:activator>
            <v-list-item-title>{{ category.name }}</v-list-item-title>
          </template>
          <v-list-item v-for="(price, index) in category.priceList" :key="`${category.id}-${price.id}-${index}`" dense
            class="pl-6" :style="{ borderTop: '1px solid lightgrey' }">
            <v-list-item-content>
              <v-list-item-title>{{ price.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ price.price | currency }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <div style="width:150px;" class="mx-auto">
                <v-btn class="ma-0" color="primary" small @click="decreaseQty(category.id, price.id)"
                  :disabled="getQty(category.id, price.id) === 0">
                  <v-icon small>mdi-minus</v-icon>
                </v-btn>
                <span class="mx-2">{{ getQty(category.id, price.id) }}</span>
                <v-btn class="ma-0" color="primary" small @click="increaseQty(category, price)"
                  :disabled="!canIncrease(category.id, price.id)">
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list-group>
      </v-list>
      <template v-if="!!$slots.default" class="rounded-lg px my-0">
        <slot></slot>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatCurrency } from "@/plugins/currencyFormater";

export default {
  name: 'SeatCategoriesPicker',
  props: {
    seatCategories: {
      type: Array,
      required: true
    },
    selectedCategories: {
      type: Array,
      default: ()=> []
    },
    personalizeTicket:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selection: this.selectedCategories,
    }
  },
  computed: {
    totalPrice() {
      return this.selection.reduce((total, category) => {
        return total + category.selectedPriceList.reduce((categoryTotal, price) => {
          const priceInfo = this.seatCategories
            .find(c => c.id === category.categoryId)
            .priceList.find(p => p.id === price.priceSegmentId);
          return categoryTotal + (priceInfo.price * price.qty);
        }, 0);
      }, 0);
    },
    totalQty() {
      return this.selection.reduce((total, category) => {
        return total + category.selectedPriceList.reduce((categoryTotal, price) => {
          return categoryTotal + price.qty;
        }, 0);
      }, 0);
    }
  },
  watch: {
    selection:{
      handler(val) {
        this.$emit('setSelectedCategories', val);
      },
      deep: true,
    }
  },
  methods: {
    getQty(categoryId, priceId) {
      const category = this.selection.find(c => c.categoryId === categoryId);
      if (!category) return 0;
      const price = category.selectedPriceList.find(p => p.priceSegmentId === priceId);
      return price ? price.qty : 0;
    },
    canIncrease(categoryId, priceId) {
      const category = this.seatCategories.find(c => c.id === categoryId);
      const price = category.priceList.find(p => p.id === priceId);
      const currentCategoryQty = this.selection.find(c => c.categoryId === categoryId)?.selectedPriceList.reduce((total, p) => total + p.qty, 0) || 0;
      const currentPriceQty = this.getQty(categoryId, priceId);

      return (category.max === 0 || currentCategoryQty < category.max) &&
        (Number(price.max) === 0 || currentPriceQty < Number(price.max));
    },
    increaseQty(category, price) {
      if (!this.canIncrease(category.id, price.id)) return;

      let selectionCategory = this.selection.find(c => c.categoryId === category.id);
      if (!selectionCategory) {
        selectionCategory = { categoryId: category.id, categoryName: category.name, selectedPriceList: [] };
        this.selection.push(selectionCategory);
      }

      let selectionPrice = selectionCategory.selectedPriceList.find(p => p.priceSegmentId === price.id);
      if (!selectionPrice) {
        selectionPrice = { priceSegmentId: price.id, priceSegmentName: price.name, value: Number(price.price), qty: 0};
        selectionCategory.selectedPriceList.push(selectionPrice);
      }

      selectionPrice.qty+=1;
      selectionPrice.ticketNames = this.mapTicketNames(selectionPrice);
    },
    decreaseQty(categoryId, priceId) {
      const categoryIndex = this.selection.findIndex(c => c.categoryId === categoryId);
      if (categoryIndex === -1) return;

      const priceIndex = this.selection[categoryIndex].selectedPriceList.findIndex(p => p.priceSegmentId === priceId);
      if (priceIndex === -1) return;

      if (this.selection[categoryIndex].selectedPriceList[priceIndex].qty > 0) {
        this.selection[categoryIndex].selectedPriceList[priceIndex].qty-=1;
        this.selection[categoryIndex].selectedPriceList[priceIndex].ticketNames = this.mapTicketNames(this.selection[categoryIndex].selectedPriceList[priceIndex]);

        if (this.selection[categoryIndex].selectedPriceList[priceIndex].qty === 0) {
          this.selection[categoryIndex].selectedPriceList.splice(priceIndex, 1);
        }

        if (this.selection[categoryIndex].selectedPriceList.length === 0) {
          this.selection.splice(categoryIndex, 1);
        }
      }
    },
    mapTicketNames(price){
      if(!this.personalizeTicket)
        return []

      return Array.from({length: (price.qty || 1)}, (v, i) => ({
        salutation: null,
        firstName: null,
        lastName: null,
      }))
    }
  },
  filters: {
    currency: formatCurrency
  }
}
</script>