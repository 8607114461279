<template>
  <v-dialog :value="true" persistent fullscreen>
    <v-card elevation="0" width="100%" height="100%" class="overflow-y-hidden">
      <v-container class="fill-height pa-0" fluid>
        <v-row no-gutters class="fill-height">
          <v-col cols="8" class="fill-height position-relative" ref="seatingPreviewCont">
            <v-menu v-model="showTicketLegend" offset-x :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn small v-bind="attrs" v-on="on" class="position-absolute" fab
                  style="top: 10px; left: 10px; z-index: 1000;">
                  <v-icon>
                    mdi-ticket-outline
                  </v-icon>
                </v-btn>
              </template>
              <v-list dense subheader>
                <v-list-item-group v-model="selectedCategory" active-class="pink--text">
                  <template v-for="(category, index) in seatCategories">
                    <v-list-item :key="category.id" :value="category.id">
                      <template v-slot:default="{ active }">
                        <v-list-item-avatar size="20" :color="category.color">
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-text="category.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                    <v-divider class="ma-0" v-if="index < category.length - 1" :key="index"></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <SeatTooltip :show="tooltipVisible" :color="toolTipColor" :x="tooltipX" :y="tooltipY"
              :row-number="tooltipRowNumber" :table-name="tooltipTableName" :seat-number="tooltipSeatNumber" :minBookableSeats="tooltipMinBookableSeats" />
              <div class="zoom-controls">
                <v-btn @click="zoomIn" small fab class="mx-0">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn @click="resetZoom" small fab class="mx-0">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-btn @click="zoomOut" small fab class="mx-0">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </div>
            <div id="seating-preview"></div>
          </v-col>
          <v-col cols="4" class="fill-height overflow-y-hidden">
            <v-btn small @click="$emit('closeDialog')" class="legend-btn" icon>
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
            <SeatsCartComponent :categoriesMap="seatCategoriesMap" :selected-seats="selectedSeats" ref="cart" 
              :tablesData="seatingData.tables" :personalizeTicket="personalizeTicket"
              @seat-removed="onCartItemRemoved" @setSelectedSeats="setSelectedSeats" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import * as d3 from "d3";
import Konva from 'konva';
import { SeatPreview } from "@/components/eventbee/seatmap/SeatPreview";
import SeatTooltip from "@/components/eventbee/seatmap/SeatTooltip.vue";
import { debounce } from "lodash";
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faAlarmClock } from "@fortawesome/pro-light-svg-icons";
import SeatsCartComponent from "./SeatsCartComponent.vue";
import { ENDPOINTS } from "../../../../config";

export default {
  name: 'SeatingMapSelect',
  components: {
    SeatTooltip,
    SeatsCartComponent
  },
  props: {
    seatingData: {
      type: Object,
      required: true
    },
    backgroundImageUrl: {
      type: String,
      default: "",
      required: true
    },
    datetime: {
      type: String,
      default: "",
    },
    ticketId: {
      type: String,
      default: "",
    },
    seatCategories: {
      type: Array,
      required: true
    },
    selectedSeats: {
      type: Array,
      default: ()=>[]
    },
    personalizeTicket: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      stage: null,
      layer: null,
      zoom: null,
      zoomScale: 1,
      minZoom: 0.4,
      maxZoom: 1.5,
      zoomStep: 0.1,
      bookingZoomThreshold: 1,
      margin: 200,
      tooltipVisible: false,
      isHoveringOnSeat: false,
      loadingReservedSeats: false,
      tooltipX: 0,
      tooltipY: 0,
      tooltipRowNumber: '',
      toolTipColor: '',
      tooltipSeatNumber: '',
      tooltipTableName: '',
      tooltipMinBookableSeats: 0,
      showTicketLegend: false,
      selectedCategory: null,
      bgLayer: null,
      reservedSeats: [],
      backgroundImage: null,
      categoryOptions: [
        { text: 'NONE', value: '', color: '#ffffff' },
        { text: 'VIP', value: 'vip', color: '#E84B3C' },
        { text: 'Premium', value: 'premium', color: '#2ECC70' },
        { text: 'Standard', value: 'standard', color: '#3398DB' },
        { text: 'Economy', value: 'economy', color: '#F2C511' },
        { text: 'Disabled', value: 'disabled', color: '#ff00ff' },
      ],
      width: 0,
      height: 0,
      seatNumberVisibilityThreshold: 1.5
    };
  },
  mounted() {
    this.$nextTick(()=>{
      setTimeout(async ()=>{
        this.updateDimensions();
        this.initializeKonva();
        await this.getReservedSeats();
        this.renderSeatingPlan();
        this.initializeD3Zoom();
        this.resetZoom();
        this.addEvents();
        window.addEventListener('resize', this.updateDimensions);
      },100)
    })
  },
  watch: {
    width(newWidth) {
      if (this.stage) {
        this.stage.width(newWidth);
      }
    },
    height(newHeight) {
      if (this.stage) {
        this.stage.height(newHeight);
      }
    },
    selectedCategory(val){
      const nodes = this.layer.find('.seat, .table-seat, .area')
      if(val){
        for(const node of nodes){
          if( node.getClassName() === 'SeatPreview' && node.attrs.category === val ){
            node.opacity(1)
          }else {
            node.opacity(0.3)
          }
        }
      }else {
        for(const node of nodes){
          node.opacity(1)
        }
      }
      this.handleCache(true);
    }
  },

  computed: {
    seatCategoriesMap() {
      return new Map(this.seatCategories.map(category => [category.id, category]));
    },
    selectedSeatsMap() {
      return new Map(this.selectedSeats.map(seat => [seat.seat_id, seat]));
    },
    tables() {
      const grouped = this.seatingData.tables?.reduce((acc, item) => {
        if (!acc[item.id]) {
          acc[item.table_id] = {
            reserveTableAfterBooking: item.reserveTableAfterBooking,
            minBookableSeats: Number(item.minBookableSeats) || 0,
            seats: item.seats.map(seat => seat.seat_id),
          };
        }
        return acc;
      }, {});

      return grouped;
    },
  },
  methods: {
    async getReservedSeats() {
      this.loadingReservedSeats = true;
      await this.axios.post(ENDPOINTS.POS.EVENTBEE.RESERVED_SEATS, {
        datetime: this.datetime,
        ticket_uuid: this.ticketId,
      }).then(res => {
        if (res.data.status) {
          this.reservedSeats = res.data.data.reserved_seats;
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
        });
      }).finally(() => {
        this.loadingReservedSeats = false;
      })

    },
    updateDimensions() {
      if (this.$refs.seatingPreviewCont && this.$refs.seatingPreviewCont) {
        this.width = this.$refs.seatingPreviewCont.clientWidth;
        this.height = this.$refs.seatingPreviewCont.clientHeight;
      }
    },
    calculateBounds() {
      const rect = this.layer.getClientRect({relativeTo: this.stage});
      return {
        x: rect.x - this.margin,
        y: rect.y - this.margin,
        width: rect.width + 2 * this.margin,
        height: rect.height + 2 * this.margin
      };
    },
    calculateMinZoom() {
      const bounds = this.calculateBounds();
      const scaleX = this.width / bounds.width;
      const scaleY = this.height / bounds.height;
      return Math.min(scaleX, scaleY);
    },
    initializeKonva() {
      this.stage = new Konva.Stage({
        container: 'seating-preview',
        width: this.width,
        height: this.height,
      });

      this.layer = new Konva.Layer({
        name: "preview"
      });

      this.bgLayer = new Konva.Layer({ name: "bgLayer", listening: false});
      this.backgroundImage = new Konva.Image({
        image: null,
        x: 0,
        y: 0,
        width: this.stage.width(),
        height: this.stage.height(),
        fill: "transparent",
        stroke: "transparent",
        strokeWidth: 0,
      });

      this.bgLayer.add(this.backgroundImage);
      
      this.stage.add(this.bgLayer);
      this.stage.add(this.layer);
    },
    setBackgroundImage(attrs, imageUrl) {
      if (imageUrl) {
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
          this.backgroundImage.setAttr("image", image)
          this.backgroundImage.moveToTop();
          this.bgLayer.batchDraw();
        };
      }

      if (attrs) {
        this.backgroundImage?.setAttrs(attrs);
      }
    },
    initializeD3Zoom() {
      const bounds = this.calculateBounds();
      this.zoom = d3.zoom()
        .touchable(true)
        .scaleExtent([this.calculateMinZoom(), this.maxZoom])
        //.extent([[0, 0], [viewportWidth, viewportHeight]])
        .translateExtent([[bounds.x, bounds.y], [bounds.x + bounds.width, bounds.y + bounds.height]])
        .on('zoom', (event) => {
          const { x, y, k } = event.transform;
          this.zoomScale = k;
          this.stage.scale({ x: k, y: k });
          this.stage.position({ x: x, y: y });
          this.stage.batchDraw();
        })
        .on('end', (e) => {
          this.handleCache();
        }).filter(() => {
          return !this.isHoveringOnSeat;
        });

      d3.select(this.stage.container())
        .call(this.zoom)
    },

    zoomIn() {
      this.smoothZoom(this.zoomScale * (2 + this.zoomStep));
    },

    zoomOut() {
      this.smoothZoom(-this.zoomScale * (2 + this.zoomStep));
    },

    resetZoom() {
      const bounds = this.calculateBounds();
      const minZoom = this.calculateMinZoom();

      // Calculate the center position
      const centerX = bounds.x + bounds.width / 2;
      const centerY = bounds.y + bounds.height / 2;

      // Create a new transform
      const transform = d3.zoomIdentity
        .translate(this.width / 2, this.height / 2)
        .scale(minZoom)
        .translate(-centerX, -centerY);

      // Apply the transform
      d3.select(this.stage.container())
        .transition()
        .duration(750) // Animation duration in milliseconds
        .call(this.zoom.transform, transform);
    },
    zoomToPointer(scale, pointerPos) {
      const container = d3.select(this.stage.container());
      const currentTransform = d3.zoomTransform(container.node());

      // Clamp the target scale to the zoom limits
      scale = Math.max(this.zoom.scaleExtent()[0], Math.min(this.zoom.scaleExtent()[1], scale));

      // Calculate the new center point
      const x = (pointerPos.x - currentTransform.x) / currentTransform.k;
      const y = (pointerPos.y - currentTransform.y) / currentTransform.k;

      // Calculate the new transform
      let newTransform = d3.zoomIdentity
        .translate(
          pointerPos.x - x * scale,
          pointerPos.y - y * scale
        )
        .scale(scale);

      // Apply the new transform with a smooth transition
      container.transition()
        .duration(300)
        .call(this.zoom.transform, newTransform);
    },
    smoothZoom(targetScale) {
      const container = d3.select(this.stage.container());
      const currentTransform = d3.zoomTransform(container.node());

      const bounds = this.calculateBounds();
      const viewportWidth = this.stage.width();
      const viewportHeight = this.stage.height();

      // Clamp the target scale to the zoom limits
      targetScale = Math.max(this.zoom.scaleExtent()[0], Math.min(this.zoom.scaleExtent()[1], targetScale));

      // Calculate the center of the viewport in the current transformed coordinate system
      const viewportCenterX = (-currentTransform.x + viewportWidth / 2) / currentTransform.k;
      const viewportCenterY = (-currentTransform.y + viewportHeight / 2) / currentTransform.k;

      // Calculate the new transform
      let newTransform = d3.zoomIdentity
        .translate(viewportWidth / 2, viewportHeight / 2)
        .scale(targetScale)
        .translate(-viewportCenterX, -viewportCenterY);

      // Apply the new transform with a smooth transition
      container.transition()
        .duration(300)
        .call(this.zoom.transform, newTransform);
    },
    updateSeatAppearance(seat) {
      if (seat.attrs.booked) {
        seat.setAttrs({
          fill: 'white', // Or any color to indicate booked status
          stroke: seat.attrs.categoryColor,
          strokeWidth: 2,
        });
      } else {
        seat.setAttrs({
          fill: seat.attrs.categoryColor,
          stroke: 'transparent',
          strokeWidth: 0,
        });
      }
      this.layer.batchDraw();
    },
    shouldReserveTableSeat(tableId){
      const tableData = this.tables[tableId];

      if(!tableData)
        return true;

      if(!tableData.reserveTableAfterBooking)
        return false;

      return tableData.seats.some(seatId=> this.reservedSeats.includes(seatId))
    },
    renderSeatingPlan() {
      this.layer.destroyChildren();
      // Render rows and seats
      this.seatingData.rows.forEach(row => {
        const rowGroup = new Konva.Group({
          ...row,
        });

        this.addRowNumbers(rowGroup, row);

        row.seats.forEach(seat => {
          const seatShape = new SeatPreview({
            ...seat,
            id: seat.seat_id,
            reserved: this.reservedSeats.includes(seat.seat_id),
            row_number: row.row_number,
            fill: this.getCategoryColor(seat.category),
            categoryColor: this.getCategoryColor(seat.category),
            fontColor: "black",
            listening: !!seat.category,
          })
          rowGroup.add(seatShape);
          if(this.selectedSeatsMap.has(seat.seat_id)){
            seatShape.setAttr('booked', true)
            this.updateSeatAppearance(seatShape)
          }
        });
        // @TODO: add a variable to hide/ show row backbone to give more insights on the seats in a row
        // const rowBackBone = new Konva.Line({
        //   name: "rowBackBone",
        //   points: row.seats.map(seat => [seat.x, seat.y]).flat(),
        //   strokeWidth: 2,
        //   stroke:'red',
        //   lineCap: 'round',
        //   lineJoin: 'round',
        //   tension: 0.5,
        //   listening: false,
        //   perfectDrawEnabled: false,
        //   shadowForStrokeEnabled: false,
        //   visible: true,
        //   hitStrokeWidth: 0,
        // })
        // rowGroup.add(rowBackBone);
        // rowBackBone.moveToBottom();
        this.layer.add(rowGroup);
      });


      // Render tables
      this.seatingData.tables.forEach(table => {
        const items = []
        let shape;
        if (table.shape.type === 'rect') {
          shape = new Konva.Rect({
            x: table.shape.x,
            y: table.shape.y,
            width: table.shape.width,
            height: table.shape.height,
            fill: 'white',
            stroke: '#BDC3C8',
            cornerRadius: 10,
            strokeWidth: 1
          });
        } else {
          shape = new Konva.Circle({
            x: table.shape.x,
            y: table.shape.y,
            radius: table.shape.radius,
            fill: 'white',
            stroke: '#BDC3C8',
            strokeWidth: 1
          });
        }

        items.push(shape);
        const reserveTabelSeats = this.shouldReserveTableSeat(table.table_id);
        table.seats.forEach(seat => {

          // handle seat reservation

          const seatShape = new SeatPreview({
            ...seat,
            id: seat.seat_id,
            reserved: this.reservedSeats.includes(seat.seat_id) || reserveTabelSeats,
            table_name: table.table_name,
            minBookableSeats: Number(table.minBookableSeats) || 0,
            table_id: table.table_id,
            fill: this.getCategoryColor(seat.category),
            categoryColor: this.getCategoryColor(seat.category),
            fontColor: "black",
            listening: !!seat.category,
            preview: true,
            rotation: 0,
          });
          
          if(this.selectedSeatsMap.has(seat.seat_id)){
            seatShape.setAttr('booked', true)
            this.updateSeatAppearance(seatShape)
          }
          
          items.push(seatShape);
        });

        const tableGroup = new Konva.Group({
          name: "table",
          ...table,
        });


        tableGroup.add(...items);

        const text = new Konva.Text({
          x: tableGroup.getClientRect().x + tableGroup.getClientRect().width / 2,
          y: tableGroup.getClientRect().y + tableGroup.getClientRect().height / 2,
          name: "table-name",
          text: table.table_name,
          fontSize: 16,
          // align: "center",
          // verticalAlign: "middle",
          padding: 10,
          visible: table.tableNameVisible,
          listening: false,
        });

        text.offsetX(text.width() / 2);
        text.offsetY(text.height() / 2);

        this.layer.add(tableGroup);
        this.layer.add(text);
      });

      if (this.seatingData.areas) {
        this.seatingData.areas.forEach(areaData => {
          let shape;
          if (areaData.isRectangle) {
            shape = new Konva.Rect({
              x: areaData.x,
              y: areaData.y,
              width: areaData.width,
              height: areaData.height,
              fill: areaData.fill,
              stroke: areaData.stroke,
              strokeWidth: areaData.strokeWidth,
              cornerRadius: areaData.cornerRadius,
              scaleX: areaData.scaleX,
              scaleY: areaData.scaleY,
              rotation: areaData.rotation,
              name: 'area-shape',
              strokeScaleEnabled: false,
              listening: false,
            });
          } else {
            shape = new Konva.Circle({
              x: areaData.x,
              y: areaData.y,
              radius: areaData.radius,
              fill: areaData.fill,
              stroke: areaData.stroke,
              strokeWidth: areaData.strokeWidth,
              scaleX: areaData.scaleX,
              scaleY: areaData.scaleY,
              rotation: areaData.rotation,
              name: 'area-shape',
              strokeScaleEnabled: false,
              listening: false,
            });
          }

          const contentGroup = new Konva.Group({
            name: 'area-content',
            listening: false,
          });

          const iconDefinition = findIconDefinition({ iconName: areaData.iconName, prefix: 'fal' });
          const icon = new Konva.Path({
            name: 'area-icon',
            data: iconDefinition ? iconDefinition.icon[4] : faAlarmClock.icon[4],
            fill: areaData.iconColor,
            scaleX: areaData.iconSize,
            scaleY: areaData.iconSize,
            visible: areaData.iconVisible,
            listening: false,
          });

          const text = new Konva.Text({
            name: 'area-text',
            text: areaData.text,
            fontSize: areaData.textSize,
            fill: areaData.textColor,
            align: 'center',
            verticalAlign: 'middle',
            visible: areaData.textVisible,
            listening: false,
          });

          contentGroup.add(icon);
          contentGroup.add(text);
          const areaGroup = new Konva.Group({ name: "area" })
          areaGroup.add(shape);
          areaGroup.add(contentGroup);
          shape.contentGroup = contentGroup;

          this.layer.add(areaGroup);
          this.updateAreaGroupLayout(shape);
        });
      }

      this.layer.draw();
      
      this.setBackgroundImage(this.seatingData.backgroundImage, this.backgroundImageUrl)
    },
    addEvents() {

      this.stage.on('mouseover', (e) => {
        if (e.target.getClassName() === "SeatPreview") {
          const seat = e.target;
          this.isHoveringOnSeat = true;

          if (seat.attrs.booked || seat.attrs.reserved || !seat.attrs.category) {
            return;
          }


          seat.setAttrs({
            fill: "white",
            stroke: seat.attrs.categoryColor,
            strokeWidth: 2,
          })
          this.showTooltip(seat);
        }
      });


      this.stage.on('mouseout', (e) => {
        if (e.target.getClassName() === "SeatPreview") {
          const seat = e.target;
          this.isHoveringOnSeat = false;

          if (seat.attrs.booked || seat.attrs.reserved || !seat.attrs.category) {
            return;
          }


          seat.setAttrs({
            fill: seat.attrs.categoryColor,
            stroke: "transparent",
            strokeWidth: 0,
          })
          this.hideTooltip();
        }
      });

      this.stage.on('click tap', (e) => {
        // this.isHoveringOnSeat = false;
        e.evt.preventDefault();
        this.hideTooltip();

        if (e.target.getClassName() === "SeatPreview") {
          if (this.zoomScale < this.bookingZoomThreshold) {
            this.zoomToPointer(this.zoomScale * (2 + this.zoomStep), this.stage.getPointerPosition());
            return;
          }
          const seat = e.target;
          if(seat.attrs.reserved || (this.selectedCategory && seat.attrs.category !== this.selectedCategory))
            return;

          this.showTooltip(seat);
          if (!seat.attrs.booked) {
              seat.setAttr('booked', this.$refs.cart.addToCart(seat));
          } else {
            seat.setAttr('booked', false)
            this.$refs.cart.removeFromCart(seat.attrs.id)
          }
          this.updateSeatAppearance(seat)
        }
      });

    },
    onCartItemRemoved(seatId) {
      const seat = this.stage.findOne(`#${seatId}`)
      if (seat) {
        seat.setAttr('booked', false)
        this.updateSeatAppearance(seat)
      }
    },
    updateAreaGroupLayout(shape) {
      const contentGroup = shape.contentGroup;
      if (!contentGroup) return;
      const icon = contentGroup.findOne('.area-icon');
      const text = contentGroup.findOne('.area-text');

      const areaDims = shape.getClientRect();
      const areaPos = shape.getClientRect();
      const iconRect = icon.getClientRect();

      // Position icon
      icon.position({
        x: -iconRect.width / 2,
        y: 0,
      });

      // Position text
      text.position({
        x: 0,
        y: iconRect.height,
      });

      // Center align text
      text.offsetX(text.width() / 2);

      // Calculate content size
      const contentHeight = contentGroup.getClientRect().height;

      // Calculate the center point of the rotated shape
      const centerX = areaPos.x + areaDims.width / 2;
      const centerY = areaPos.y + areaDims.height / 2;

      // Position content group at the center of the rotated shape
      contentGroup.absolutePosition({
        x: centerX,
        y: centerY - contentHeight / 2, // Adjust Y to center vertically
      });
      contentGroup.cache();
    },
    getCategoryColor(categoryId) {
      const category = this.seatCategoriesMap.get(categoryId);
      return category ? category.color : 'gray'; // Default color if category not found
    },
    fitToContainer() {
      const padding = 50;
      const scale = Math.min(
        this.width / (this.layer.width() + padding * 2),
        this.height / (this.layer.height() + padding * 2)
      );

      const centerX = (this.width - this.layer.width() * scale) / 2;
      const centerY = (this.height - this.layer.height() * scale) / 2;

      this.layer.scale({ x: scale, y: scale });
      this.layer.position({ x: centerX, y: centerY });
      this.layer.batchDraw();

      // Reset D3 zoom
      d3.select(this.stage.container()).call(
        this.zoom.transform,
        d3.zoomIdentity.translate(centerX, centerY).scale(scale)
      );
    },
    addRowNumbers(rowGroup, row) {
      const rowNumberPositions = row.row_number_position || [];
      const seats = row.seats;
      if (seats.length < 2) return;

      const addRowNumber = (position, seatIndex1, seatIndex2) => {
        const seat1 = seats[seatIndex1];
        const seat2 = seats[seatIndex2];

        // Calculate the vector between the two seats
        let dx = seat2.x - seat1.x;
        let dy = seat2.y - seat1.y;

        // Normalize the vector
        const length = Math.sqrt(dx * dx + dy * dy);
        dx = dx / length;
        dy = dy / length;

        // Determine the offset direction based on the position
        const offsetMultiplier = -1 //position === 'Start' ? -1 : 1;

        // Set the offset distance
        const offset = 30; // Adjust this value to change how far the number is from the seat

        // Calculate the position for the row number
        const x = seat1.x + offsetMultiplier * dx * offset;
        const y = seat1.y + offsetMultiplier * dy * offset;

        const text = new Konva.Text({
          name: "rowNumber",
          x: x,
          y: y,
          text: row.row_number,
          fontSize: 12,
          fill: 'black',
          align: 'center',
          verticalAlign: 'middle',
          rotation: -rowGroup.rotation(),
        });

        // Adjust the offset of the text so it's centered on its position
        text.offsetX(text.width() / 2);
        text.offsetY(text.height() / 2);

        rowGroup.add(text);
      };

      if (rowNumberPositions.includes('Start')) {
        addRowNumber('Start', 0, 1);
      }
      if (rowNumberPositions.includes('End')) {
        addRowNumber('End', seats.length - 1, seats.length - 2);
      }
    },
    showTooltip(seat) {
      const mousePos = this.stage.getPointerPosition();
      this.tooltipX = mousePos.x;
      this.tooltipY = mousePos.y - 5;
      this.tooltipRowNumber = seat.attrs.row_number;
      this.tooltipSeatNumber = seat.attrs.seat_number;
      this.tooltipTableName = seat.attrs.table_name;
      this.tooltipMinBookableSeats = Number(seat.attrs.minBookableSeats || 0);
      this.toolTipColor = this.getCategoryColor(seat.attrs.category);
      this.tooltipVisible = true;
    },
    hideTooltip() {
      this.tooltipVisible = false;
    },
    updateTooltipPosition() {
      const mousePos = this.stage.getPointerPosition();
      if (mousePos && this.tooltipVisible) {
        this.tooltipX = mousePos.x;
        this.tooltipY = mousePos.y - 5;
      }
    },
    handleCache: debounce(function (force) {
      if (!this.layer.isCached() && this.zoomScale < this.bookingZoomThreshold && !force) {

        this.layer.clearCache();
        const nodes = this.layer.find('.seat, .rowNumber, .table');

        for (let i = 0; i < nodes.length; i++) {
          nodes[i].visible(true);
        }
        this.layer.cache({});
        return;
      } else if (this.layer.isCached() && this.zoomScale < this.bookingZoomThreshold && !force) {
        return;
      }

      this.layer.clearCache();
      // Cache DOM queries
      const nodes = this.layer.find('.seat, .rowNumber, .table');

      const chunkSize = 1000;
      let processedNodes = 0;

      const processChunk = () => {
        const endIndex = Math.min(processedNodes + chunkSize, nodes.length);

        for (let i = processedNodes; i < endIndex; i++) {
          const node = nodes[i];

          const isVisible = node.isClientRectOnScreen()
          node.visible(isVisible);
        }

        processedNodes = endIndex;

        if (processedNodes < nodes.length) {
          // Schedule next chunk
          requestAnimationFrame(processChunk);
        } else {
          // All chunks processed, perform final draw
          this.layer.batchDraw();
        }
      };

      // Start processing chunks
      requestAnimationFrame(processChunk);
    }, 300),
    setSelectedSeats(data) {
      if(data.close){
        this.$emit("closeDialog");
      }
      this.$emit("setSelectedSeats", data.seats);
    },
  },
  beforeDestroy() {
    if (this.stage) {
      this.stage.off('dragstart');
      this.stage.off('dragend');
      this.stage.off('click tap');
      this.stage.destroy();
      this.stage = null;
    }
    window.removeEventListener('resize', this.updateDimensions);
  },
}
</script>

<style scoped>
#seating-preview {
  width: 100%;
  height: 100vh !important;
  touch-action: none;
  /* Prevents browsers' touch actions */
  user-select: none;
  /* Prevents text selection */
}

.zoom-controls {
  position: absolute;
  top: 60px;
  left: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.legend-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
}
</style>