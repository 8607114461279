<template>
  <v-row class="white mx-0 radius-sm pa-2 mx-0 pt-2 my-2 px-4 radius-sm">
    <v-col
        class="rounded-lg px my-0"
        v-for="(price, i) in prices"
        :key="price.uuid"
        cols="12"
    >
      <v-row
          no-gutters
          v-bind:class="[prices.length > i + 1 ? 'border-bottom' : '']"
      >
        <v-col id="price-name" cols="12" md="8">
          {{ price.name }}
        </v-col>

        <v-col cols="12" md="2" class="ma-auto" id="price-value">
          {{ price.value | currency }}
        </v-col>

        <v-col cols="12" md="2" class="text-right">
          <v-btn
              id="quantity-btn-minus"
              class="quantity-btn px-1 pa-0 d-inline"
              depressed
              :disabled="priceQuantity(price) < 1"
              @click="decreasePriceQuantity(price)"
              color="primary"
          >
            <font-awesome-icon class="white--text" :icon="['fal','minus']"></font-awesome-icon>
          </v-btn>
          <strong class="quantity"> {{ priceQuantity(price) }} </strong>

          <v-btn
              max-width="30"
              width="30px"
              id="quantity-btn-plus"
              class="quantity-btn px-0 ma-0"
              depressed
              @click="addPrice(price)"
              :disabled="!canAddPersons(price)"
              color="primary"
          >
            <font-awesome-icon class="white--text" :icon="['fal','plus']"></font-awesome-icon>
          </v-btn>
        </v-col>
        <v-col
            v-if="price.requiresBooking"
            cols="12"
            class="text-right error--text"
        >
          <span> {{ $t('eventbee.lang_required_ticket') }} </span>
        </v-col>
      </v-row>
    </v-col>
    <v-col
        v-if="!!$slots.default"
        class="rounded-lg px my-0"
        cols="12"
    >
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {
  faPlus,faMinus
} from "@fortawesome/pro-light-svg-icons"
import {library} from '@fortawesome/fontawesome-svg-core';

library.add(faPlus,faMinus);

export default {
  name: "TicketsPickerComponent",
  components:{FontAwesomeIcon},
  props: {
    prices: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
    maxPlaces: {
      type: Number,
      default: 0
    },
    personalizeTicket:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    selected_prices: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    /**
     * calculate total persons based on quantity and number of persons per price
     *
     * @returns {T}
     */
    totalPlacesToBook() {
      if (
          !Array.isArray(this.selected_prices) ||
          (Array.isArray(this.selected_prices) &&
              this.selected_prices?.length === 0)
      )
        return 0;

      return this.selected_prices.reduce(
          (acc, price) =>
              acc + parseInt(price.selected_quantity * price.quantity),
          0
      );
    },
    canAddPersons() {
      return priceSegment => {
        let tmpSegment = this.selected_prices.find(
            p => p.id === priceSegment.id
        );
        let isMaxNotReach = true;
        if (tmpSegment)
          isMaxNotReach =
              priceSegment.max === null ||
              priceSegment.max === undefined ||
              Number(priceSegment.max) === 0 ||
              (priceSegment.max &&
                  tmpSegment.selected_quantity < Number(priceSegment.max));
        return (
            this.totalPlacesToBook + priceSegment.quantity <= this.maxPlaces &&
            isMaxNotReach
        );
      };
    },
    /**
     * quantity of given price (ticket)
     * @returns {(function(*))|*}
     */
    priceQuantity() {
      return price => {
        if (!Array.isArray(this.selected_prices)) return 0;

        let tmp = this.selected_prices.find(p => p.id === price.id);
        if (tmp) {
          let index = this.selected_prices.indexOf(tmp);
          if (index > -1) return this.selected_prices[index].selected_quantity;
        }
        return 0;
      };
    }
  },
  methods: {
    /**
     * increment quantity of given price
     *
     * @param price - price object
     */
    addPrice(price) {
      if (typeof price !== 'object') return;

      if (!Array.isArray(this.selected_prices)) {
        this.selected_prices = [];
      }

      //let's check if this price is already selected
      let tmp = this.selected_prices.find(({ id }) => {
        return id === price.id;
      });

      if (tmp) {
        let index = this.selected_prices.indexOf(tmp);
        if (index > -1) {
          this.selected_prices[index].selected_quantity =
              parseInt(this.selected_prices[index].selected_quantity) + 1;
          this.selected_prices[index].ticketNames = this.mapTicketNames(this.selected_prices[index]);
        }
      } else {
        this.selected_prices = [
          ...this.selected_prices,
          {
            id: price.id,
            name: price.name,
            value: price.value,
            requiresBooking: price.requiresBooking,
            issue_ticket_foreach_kontingent: price.issue_ticket_foreach_kontingent,
            selected_quantity: 1,
            quantity: price.quantity,
            ticketNames: this.mapTicketNames(price),
          }
        ];
      }
    },

    /**
     * decrease quantity or delete if quantity is zero
     *
     * @param price
     */
    decreasePriceQuantity(price) {
      if (typeof price !== 'object') return;

      if (!Array.isArray(this.selected_prices)) {
        this.selected_prices = [];
      }

      //let's check if this price is already selected
      let tmp = this.selected_prices.find(({ id }) => {
        return id === price.id;
      });

      if (tmp) {
        let index = this.selected_prices.indexOf(tmp);
        if (index > -1) {
          if (parseInt(this.selected_prices[index].selected_quantity) - 1 >= 1) {
            this.selected_prices[index].selected_quantity =
                parseInt(this.selected_prices[index].selected_quantity) - 1;
            this.selected_prices[index].ticketNames = this.mapTicketNames(this.selected_prices[index]);
          } else {
            this.selected_prices.splice(index, 1);
          }
        }
      }
    },
    mapTicketNames(price){
      if(!this.personalizeTicket)
        return []
      
      if(price.issue_ticket_foreach_kontingent){
        return Array.from({ length: (price.selected_quantity || 1) * price.quantity }, (v, i) => (
          {
            salutation: null,
            firstName: null,
            lastName: null
          }
        ))
      }

      return Array.from({length: (price.selected_quantity || 1)}, (v, i) => ({
        salutation: null,
        firstName: null,
        lastName: null
      }))
    }
  }
};
</script>

<style scoped>
#prices-section {
  background-color: white;
}

#price-name {
  font-size: 18px;
  font-weight: bold;
}

#price-value {
  font-size: 18px;
  color: #929292;
  text-align: right;
  padding-right: 10px;
}

.quantity-btn {
  font-size: 30px;
  max-width: 20px !important;
}

.quantity {
  min-width: 50px !important;
  width: 50px !important;
}

#quantity-btn-minus {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;

  top: 0px;
  left: 0;
  height: 45px;
  width: 45px !important;
  min-width: 45px !important;
  min-height: 45px !important;
  padding: 0 !important;
  bottom: 0;
  user-select: none;
}

#quantity-btn-plus {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;

  top: 0px;
  right: 0;
  height: 45px;
  width: 45px !important;
  min-width: 45px !important;
  min-height: 45px !important;
  padding: 0 !important;
  bottom: 0;
  user-select: none;
}

#qty-input {
  height: 45px !important;
  max-width: 45px !important;
}

#total,
#ticket-information-title {
  font-size: 18px;
}

@media screen and (max-width: 600px) {
  #price-value {
    text-align: left;
  }
}
</style>

<style>
input#qty-input {
  text-align: center !important;
}

.qty-input > .v-input__control > .v-input__slot:before {
  content: '' !important;
  width: 0 !important;
  min-height: 45px !important;
}
</style>
