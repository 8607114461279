<template>
  <v-container fluid>
    <div role="button" @click="toggle()" class="d-flex justify-space-between white pa-2 radius-sm">
      <div class="d-flex align-center">
        <font-awesome-icon :icon="['fal','ticket-alt']" class="primary--text d-inline" size="2x"></font-awesome-icon>
        <span class="font-weight-bold ml-4 d-inline">{{ $t('generic.lang_whoIsComing') }}?</span>
      </div>
      <div class="d-flex flex-column">
        <v-btn rounded text>
          <font-awesome-icon :icon="['fal',
                      expanded
                        ? 'chevron-up'
                        : 'chevron-down']
                    " class="primary--text d-inline" size="2x"></font-awesome-icon>
        </v-btn>
      </div>
    </div>

    <v-expand-transition>
      <div class="white radius-sm" v-if="expanded">

        <template v-if="hasCategories">
          <template v-if="seatSelectionMode === 'choose-seat'">
            <v-container v-for="seat in selectedSeats" :key="seat.seat_id" fluid>
              <template v-if="seat.ticketNames.length">
                <h5>{{ seat.categoryName }} - {{ seat.priceSegmentName }}</h5>
                <h6 class="text-muted">{{ seat.table_name?`${$t('generic.lang_table')} ${seat.table_name}, ${$t('eventbee.lang_seat')} ${seat.seat_number}`: `${$t('eventbee.lang_row')} ${seat.row_number}, ${$t('eventbee.lang_seat')} ${seat.seat_number}` }}</h6>
                <v-row v-for="(name, index) in seat.ticketNames" :key="seat.seat_id + '-' + index">
                  <v-col cols="12">
                    <v-radio-group v-model="name.salutation" row hide-details :rules="[rules.required]" class="mt-1"
                      :label="seat.ticketNames.length > 1 ? `${$t('generic.lang_person')} ${index + 1} *` : ''">
                      <v-radio v-for="(sal, optIndex) in salutations" :key="seat.seat_id + '-' + index + '-' + optIndex"
                        :label="sal.name" :value="sal.value"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="name.firstName" :label="$t('generic.lang_name')"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL" hide-details :rules="[rules.required]"
                      @focus="showTouchKeyboard" outlined dense class="pt-0" />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="name.lastName" :label="$t('customers.lang_lastName')"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard" :rules="[rules.required]"
                      hide-details outlined dense class="pt-0" />
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </template>
          <template v-else>
            <v-container v-for="category in selectedCategories" :key="category.categoryId" fluid>
              <h4>{{ category.categoryName }}</h4>
              <v-row v-for="(price, index) in category.selectedPriceList"
                :key="category.categoryId + '-' + price.priceSegmentId + '-' + index" no-gutters>
                <v-col cols="12">
                  <h5 class="text-muted my-2">{{ price.priceSegmentName }}</h5>
                  <template v-if="price.ticketNames.length">
                    <v-row v-for="(name, index) in price.ticketNames"
                      :key="category.categoryId + '-' + price.priceSegmentId + '-' + index">
                      <v-col cols="12">
                        <v-radio-group v-model="name.salutation" row hide-details :rules="[rules.required]" class="mt-1"
                          :label="price.ticketNames.length > 1 ? `${$t('generic.lang_person')} ${index + 1} *` : ''">
                          <v-radio v-for="(sal, optIndex) in salutations"
                            :key="category.categoryId + '-' + price.priceSegmentId + '-' + index + '-' + optIndex"
                            :label="sal.name" :value="sal.value"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field v-model="name.firstName" :label="$t('generic.lang_name')"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL" hide-details :rules="[rules.required]"
                          @focus="showTouchKeyboard" outlined dense class="pt-0" />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field v-model="name.lastName" :label="$t('customers.lang_lastName')"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard" :rules="[rules.required]"
                          hide-details outlined dense class="pt-0" />
                      </v-col>
                    </v-row>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </template>
        <template v-else>
          <v-container v-for="price in selectedPrices" :key="price.id" fluid>
            <template v-if="price.ticketNames.length">
              <h5>{{ price.name }}</h5>
              <v-row v-for="(name, index) in price.ticketNames" :key="price.id+'-'+index">
                <v-col cols="12">
                  <v-radio-group v-model="name.salutation" row hide-details :rules="[rules.required]" class="mt-1"
                    :label="price.ticketNames.length > 1 ?`${$t('generic.lang_person')} ${index+1} *`:''">
                    <v-radio v-for="(sal, optIndex) in salutations" :key="price.id + '-' + index + '-' + optIndex"
                      :label="sal.name" :value="sal.value"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="name.firstName" :label="$t('generic.lang_name')"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL" hide-details :rules="[rules.required]"
                    @focus="showTouchKeyboard" outlined dense class="pt-0" />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="name.lastName" :label="$t('customers.lang_lastName')"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard" :rules="[rules.required]"
                    hide-details outlined dense class="pt-0" />
                </v-col>
              </v-row>
            </template>
          </v-container>
        </template>
        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard" style="z-index: 299 !important;">
          <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
            :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet" :input="touchKeyboard.input"
            :layout="touchKeyboard.layout" :options="touchKeyboard.options" class="internalWidthExpanded" />
        </div>
      </div>
    </v-expand-transition>
  </v-container>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import KeyboardMixIns from "@/mixins/KeyboardMixIns";
import validation from "../../../../mixins/validation/validation";
export default {
  name: 'CustomAttributesForm',
  components:{
    FontAwesomeIcon
  },
  mixins:[KeyboardMixIns, validation],
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    selectedPrices: {
      type: Array,
      default: () => []
    },
    selectedCategories: {
      type: Array,
      default: () => []
    },
    selectedSeats: {
      type: Array,
      default: () => []
    },
    hasCategories: {
      type: Boolean
    },
    seatSelectionMode: {
      type: String
    },
    expanded: {
      type: Boolean
    }
  },
  data() {
    return {
      
    };
  },
  watch: {
    expanded(v){

    }
  },
  computed: {
    salutations(){
      return [
        {
          name: this.$t('generic.lang_ms'),
          value: 'Mrs',
        },
        {
          name: this.$t('generic.lang_mr'),
          value: 'Mr',
        },
        {
          name: this.$t('generic.lang_other'),
          value: 'Other',
        }
      ]
    }
  },
  methods: {
    toggle() {
      this.$emit('toggle');
    }
  },
  beforeMount() {}
};
</script>

<style scoped></style>
