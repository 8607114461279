<!-- CartComponent.vue -->
<template>
  <v-card class="overflow-y-hidden" height="100%" style="border-left: 1px solid lightgray;">
    <v-card-title>{{ $t('eventbee.lang_yourOrder') }}</v-card-title>
    <v-card-text class="cart-content">
      <template v-if="groupedCartItems">
        <v-alert v-if="tableBookingErrors.length" dense type="warning" outlined>
          <ul>
            <li v-for="(error, index) in tableBookingErrors" :key="index">{{ `${$t('generic.lang_table')} ${error.name} : ${$t('eventbee.lang_bookAtLeast').replace('{seats}', error.min)}` }}</li>
          </ul>
        </v-alert>
        <v-list dense class="pa-0">
          <v-list-group dense v-for="group in groupedCartItems" :key="group.category" v-model="group.active" no-action
            :style="{ borderLeft: `10px solid ${group.color} !important`, borderBottom: '1px solid lightgrey' }">
            <template v-slot:activator>
              <v-list-item-title>{{ group.name }} ({{ group.items.length }})</v-list-item-title>
            </template>
            <v-list-item v-for="item in group.items" :key="item.seat_id" dense class="pl-6"
              :style="{ borderTop: '1px solid lightgrey' }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name || seatName(item) }}</v-list-item-title>
                <v-list-item-subtitle>
                  <v-select v-model="item.priceSegmentId" :items="getPriceOptions(item.category, item.priceSegmentId)" item-text="label" outlined item-value="id" dense hide-details @change="updateItemPrice(item)"></v-select>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon small @click="removeFromCart(item.seat_id)">
                  <v-icon small color="error">mdi-trash-can</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-group>
        </v-list>
      </template>
    </v-card-text>
    <div class="cart-action">
      <v-divider class="ma-0" />
      <v-card-actions>
        <v-row no-gutters justify="center">
          <v-col cols="6">
            <span class="text-h6 text-capitilize">{{ $t('generic.lang_total') }}</span>
          </v-col>
          <v-col cols="6" class="text-right">
            <span class="text-h6">{{ totalPrice | currency }}</span>
          </v-col>
          <v-col cols="10" class="text-right">
            <v-btn block color="primary" large @click="setSelectedSeats(true)" :disabled="!valid">
              {{ $t('eventbee.lang_selectSeats') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
  import { formatCurrency } from "@/plugins/currencyFormater";

  export default {
    name: 'SeatsCartComponent',
    props: {
      categoriesMap: {
        type: Map,
        required: true
      },
      selectedSeats: {
        type: Array,
        default: ()=>[]
      },
      tablesData: {
        type: Array,
        default: ()=> []
      },
      personalizeTicket: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        cartItems: JSON.parse(JSON.stringify(this.selectedSeats)),
        showMobileCart: false,
        isMobile: false
      }
    },
    computed: {
      valid(){
        return this.cartItems.length> 0 && this.tableBookingErrors.length === 0;
      },
      totalPrice() {
        return this.cartItems.reduce((total, item) => total + Number(item.value), 0)
      },
      groupedCartItems() {
        const grouped = this.cartItems.reduce((acc, item) => {
          if (!acc[item.category]) {
            acc[item.category] = {
              category: item.category,
              name: this.getCategoryName(item.category),
              color: this.getCategoryColor(item.category),
              // priceList: this.getCategoryPriceList(item.category),
              items: []
            };
          }
          acc[item.category].items.push(item);
          return acc;
        }, {});

        return grouped;
      },
      tables() {
        const bookedSeats = this.cartItems.map(seat => seat.seat_id);
        const grouped = this.tablesData?.reduce((acc, item) => {
          if (!acc[item.id]) {
            acc[item.table_id] = {
              name: item.table_name,
              id: item.table_id,
              minBookableSeats: Number(item.minBookableSeats) || 0,
              seats: item.seats.map(seat => seat.seat_id),
            };
            acc[item.table_id].bookedSeats = Array.from((new Set(acc[item.table_id].seats)).intersection(new Set(bookedSeats)));
            acc[item.table_id].isValid = acc[item.table_id].minBookableSeats > 1 && acc[item.table_id].bookedSeats.length > 0 ? acc[item.table_id].bookedSeats.length >= acc[item.table_id].minBookableSeats : true;
          }
          return acc;
        }, {});

        return grouped;
      },
      tableBookingErrors() {
        return Object.values(this.tables).filter(table => !table.isValid).map(table => ({ name: table.name, min: table.minBookableSeats, id: table.id, bookedSeats: table.bookedSeats }));
      }
    },
    methods: {
      seatName(cartItem){
        return cartItem.table_name
            ? `${this.$t('generic.lang_table')} ${cartItem.table_name} - ${this.$t('eventbee.lang_seat')} ${cartItem.seat_number}`
            : `${this.$t('eventbee.lang_row')} ${cartItem.row_number} - ${this.$t('eventbee.lang_seat')} ${cartItem.seat_number}`;
      },
      canAddSeat(categoryId, priceSegmentId, ignoreCategory = false) {
        const category = this.categoriesMap.get(Number(categoryId));
        if (!category) return false;
        // Check category max
        if (!ignoreCategory && category.max !== 0 && this.groupedCartItems[categoryId]?.items.length >= category.max) {
          return false;
        }

        const priceSegment = category.priceList.find(p => p.id === priceSegmentId);
        if (!priceSegment) return false;

        // Check price segment max
        const priceSegmentCount = this.groupedCartItems[categoryId]?.items.filter(item => item.priceSegmentId === priceSegmentId).length || 0;
        return Number(priceSegment.max) === 0 || priceSegmentCount < Number(priceSegment.max);
      },
      addToCart(seat) {
        const category = this.categoriesMap.get(Number(seat.attrs.category));
        if (!category) return false;

        let priceSegment = category.priceList.find(p => p.standard) || category.priceList[0];
        let canBook = this.canAddSeat(seat.attrs.category, priceSegment.id);
        
        for (let i = 0; i < category.priceList.length && !canBook; i++) {
          if (this.canAddSeat(seat.attrs.category, category.priceList[i].id)) {
            priceSegment = category.priceList[i]
            canBook = true;
            break;
          }
        }

        if (!canBook) {
          return false;
        }

        const cartItem = {
          seat_id: seat.attrs.seat_id,
          table_name: seat.attrs.table_name,
          row_number: seat.attrs.row_number,
          seat_number: seat.attrs.seat_number,
          name: seat.attrs.table_name
            ? `${this.$t('generic.lang_table')} ${seat.attrs.table_name} - ${this.$t('eventbee.lang_seat')} ${seat.attrs.seat_number}`
            : `${this.$t('eventbee.lang_row')} ${seat.attrs.row_number} - ${this.$t('eventbee.lang_seat')} ${seat.attrs.seat_number}`,
          category: seat.attrs.category,
          priceSegmentId: priceSegment.id,
          categoryName: category.name,
          priceSegmentName: priceSegment.name,
          value: priceSegment.price,
          ticketNames: this.personalizeTicket ? [{
            salutation: null,
            firstName: null,
            lastName: null
          }]:[]
        };
        this.cartItems.push(cartItem);
        this.groupedCartItems[seat.attrs.category].active = true;
        return true;
      },
      removeFromCart(itemId) {
        let removed = false;
        Object.values(this.tables).forEach((table)=>{
          if(table.bookedSeats.length < 1 || table.minBookableSeats < 2 || table.bookedSeats.length > table.minBookableSeats)
            return;

          if(table.bookedSeats.includes(itemId)){
            removed = true;
            table.bookedSeats.forEach(seatId=> {
              const index = this.cartItems.findIndex(item => item.seat_id === seatId)
              if (index !== -1) {
                this.cartItems.splice(index, 1)
                this.$emit('seat-removed', seatId)
              }
            })
          }
        })
        
        if(!removed){
          const index = this.cartItems.findIndex(item => item.seat_id === itemId)
          if (index !== -1) {
            this.cartItems.splice(index, 1)
            this.$emit('seat-removed', itemId)
          }
        }
        this.setSelectedSeats();
      },
      getPriceOptions(categoryId, priceSegmentId) {
        const category = this.categoriesMap.get(Number(categoryId))
        return category.priceList.map(price => ({
          id: price.id,
          label: `${price.name} ${formatCurrency(price.price)} ${price.standard ? '(Standard)' : ''}`,
          price: price.price,
          max: Number(price.max),
          disabled: !this.canAddSeat(categoryId, price.id, true) && priceSegmentId !== price.id,
        }))
      },
      updateItemPrice(item) {
        const category = this.categoriesMap.get(item.category);
        const currentPriceSegment = category.priceList.find(p => p.id === item.priceSegmentId);
        const newPriceSegment = category.priceList.find(p => p.id === item.priceSegmentId);

        if (this.canAddSeat(item.category, newPriceSegment.id)) {
          item.value = newPriceSegment.price;
          item.priceSegmentId = newPriceSegment.id;
          item.priceSegmentName = newPriceSegment.name;
        } else {
          // Revert to the previous price segment if the new one is not available
          item.priceSegmentId = currentPriceSegment.id;
          item.value = currentPriceSegment.price;
          item.priceSegmentName = currentPriceSegment.name;
        }
      },
      getCategoryName(categoryId) {
        const category = this.categoriesMap.get(Number(categoryId));
        return category ? category.name : "No found"
      },
      getCategoryColor(categoryId) {
        const category = this.categoriesMap.get(Number(categoryId));
        return category ? category.color : 'grey'; // Default color if category not found
      },
      getCategoryPriceList(categoryId) {
        const category = this.categoriesMap.get(Number(categoryId));
        return category ? category.priceList : [];
      },
      setSelectedSeats(close = false) {
        this.$emit('setSelectedSeats', {
          close,
          seats: this.cartItems.map(item => ({
            seat_id: item.seat_id,
            table_name: item.table_name,
            row_number: item.row_number,
            seat_number: item.seat_number,
            category: item.category,
            priceSegmentId: item.priceSegmentId,
            value: item.value,
            categoryName: item.categoryName,
            priceSegmentName: item.priceSegmentName,
            ticketNames: this.personalizeTicket ? [{
              salutation: null,
              firstName: null,
              lastName: null
            }]:[]

          })) 
        });
      },
      checkMobile() {
        this.isMobile = window.innerWidth < 600
      }
    },
    mounted() {
      this.checkMobile()
      window.addEventListener('resize', this.checkMobile)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.checkMobile)
    }
  }
</script>

<style scoped>

.cart-action {
  position: absolute;
  bottom: 0;
  width: 100%;
}


.cart-content {
  max-height: calc(100% - 180px);
  overflow-y: auto;
}
</style>